@import "ui/styles/mixins";
.container {
  user-select: none;
  width: 100%;
}

.inner {
  display: flex;
  align-items: center;
  padding: 5px 20px 20px 20px;
  flex-direction: column;
  justify-items: center;
  background-color: var(--uiBoxBackground);
  border-radius: 4px;

  @media (min-width: 375px) {
    flex-direction: row;
    padding: 5px 10px 5px 20px;
  }
  @media (min-width: 600px) {
    padding: 15px 15px 15px 40px;
  }

  transition: background-color 100ms linear;
  @media (hover: hover) {
    &:hover {
      --isHover: 1;
      background-color: var(--uiHover);
    }
  }

  &.noBorder {
    padding: 0;
  }
}

.coverOuter {
  display: flex;
  align-items: center;
  height: 165px;
  @media (min-width: 600px) {
    height: 180px;
  }
}

.coverInner {
  position: relative;
  width: 80px;
  flex: 0 0 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate3d(0, calc(var(--isHover) * -1px), 0); // Safari suff
  transition: transform 300ms ease;
  @media (min-width: 600px) {
    width: 95px;
    flex: 0 0 95px;
  }
}

.stickers {
  width: 30px;
  position: absolute;
  top: -13px;
  right: -14px;
  -webkit-transform: translate3d(0, 0, 0); // Safari suff

  > * {
    margin-bottom: 2px;
  }
}

.bookInfo {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  @media (min-width: 375px) {
    margin-left: 30px;
  }
  @media (min-width: 600px) {
    margin-left: 40px;
  }
}

.title {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.authors {
  width: 100%;
  margin: 8px 0 16px;
  color: var(--textDiscreet);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.actions {
  display: flex;
  align-self: center;
  margin-top: 5px;
  @media (min-width: 375px) {
    margin: 0;
    align-self: flex-start;
  }
}

.noBorder {
  border: none;
  .inner {
    padding: 0;
  }
  .coverOuter {
    height: auto;
  }
}
