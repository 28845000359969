@import "ui/styles/mixins";
.progress {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.default {
  background-image: url('/img/goalprogressillustrative.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.svg {
  position: absolute;
  top: 0;
  left: 0;
}

.circle {
  transition: all 400ms linear;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.data {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.readCount {
  display: flex;
  align-items: center;
  &.complete {
    color: var(--uiWhite);
  }
}

.extra-large {
  font-size: 98px;
  height: 118px;
}

.large {
  font-size: 52px;
  height: 63px;
}

.medium {
  font-size: 36px;
  height: 44px;
}

.small {
  font-size: 16px;
  color: var(--textSecondary);
}

.target {
  color: var(--textDiscreet);
  flex-shrink: 1;
  &.complete {
    color: var(--uiWhite);
    opacity: 0.6;
  }
  &.largeText {
    font-size: 30px;
  }
}
