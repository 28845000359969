@import "ui/styles/mixins";
.container {
  padding: var(--outlineWidth) var(--size-4) 0 var(--size-4);
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--uiBoxBackground);
  position: relative;
  @media (min-width: 600px) {
    border-radius: var(--size-1) var(--size-1) 0 0;
    border-top: none;
  }
  overflow-x: hidden;
}

.progressView {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
}

.progress {
  color: var(--accentPrimary);
}

.readingProgressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: var(--outlineWidth);
  background-color: var(--accentPrimary);
}
