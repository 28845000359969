@import "ui/styles/mixins";
.formHolder {
  width: 100%;
  display: flex;
  align-items: center;
}

.inputWrap {
  margin: 0;
  position: relative;
  max-width: 80px;
  overflow: hidden;
  height: 100%;
  min-height: 26px;
  @media (min-width: 600px) {
    min-height: 100%;
  }
  & .input {
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: transparent;
    overflow: hidden;
    &::placeholder {
      color: var(--textDiscreet);
    }
  }
}

.capacityInputWrap {
  min-width: 4rem;
  &.input {
    left: 0;
    &::placeholder {
      padding-left: 4px;
    }
  }
}

.widthMachine {
  height: 0;
  visibility: hidden;
  max-width: 90px;
  width: 100%;
  overflow: hidden;
  padding: 0 2px;
}

.inputs {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1px 0;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > *:first-child {
    margin-right: var(--size-2);
  }
}

.select {
  // transition: all ease-in-out 200ms;
  border: none;
  appearance: none;
  text-decoration: underline;
  color: var(--textDiscreet);
  font: inherit;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
}

.durationInput {
  padding: 1px 2px;
  border: none;
  background-color: transparent;
  max-width: 60px;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  @media (min-width: 600px) {
    width: 100%;
  }
  &::placeholder {
    color: var(--textDiscreet);
  }
}
