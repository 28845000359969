@import "ui/styles/mixins";
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin-bottom: var(--size-2);
  color: var(--textSecondary);
  svg {
    width: 16px;
    height: 16px;
  }
}
