@import "ui/styles/mixins";
.container {
}

.spacingLeft {
  @media (min-width: 600px) {
    padding-left: 54px;
  }
}

.link {
  display: block;
  padding: var(--size-4) var(--size-5);
  border-radius: 4px;
  border: 1px solid var(--uiOutline);
  position: relative;
}

.unreadLink {
  padding-bottom: var(--size-10);
}

a.link {
  transition: 150ms border-color linear;

  @media (hover: hover) {
    &:hover {
      border-color: var(--uiBase);
    }
  }

  @include darkModeAndHover {
    &:hover {
      border-color: var(--uiBaseLight);
    }
  }
}

.content {
  // @include limitTextLines(3);
}

.reactionBar {
  margin: var(--size-1) -20px -20px;
}

.borderTop {
  border-top: 1px solid var(--uiOutline);
}

.header {
  margin-bottom: var(--size-5);
}

.commentsCount {
  margin-top: var(--size-4);
  color: var(--textDiscreet);
}

.unread {
  position: absolute;
  bottom: var(--size-4);
  right: var(--size-5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--textDiscreet);
}

.unreadDot {
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;
  background-color: var(--accentPrimary);
  margin-left: var(--size-1);
}
