@import "ui/styles/mixins";
.container {
  position: relative;
  overflow: hidden;
  will-change: maxHeight;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 160px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 200ms linear;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 75%);

    @media (prefers-color-scheme: dark) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 75%);
    }
  }
}
.limited {
  &:before {
    opacity: 1;
    visibility: visible;
  }
}
.button {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;

  @media (min-width: 600px) {
    left: 54px;
    width: calc(100% - 54px);
  }
}
