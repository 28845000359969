@import "ui/styles/mixins";
.container {
  width: 1200px;
  height: 630px;
  overflow: hidden;
  display: flex;
  padding: 70px;
  gap: 70px;
  background-color: var(--uiWhite);
  h1 {
    display: inline;
  }
}

.left {
  flex: 0 0 355px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  box-sizing: border-box;
  position: relative;
  align-self: center;
}

.logo {
  flex: 0 0 42px;
  display: flex;
  align-self: flex-start;
  svg {
    width: 42px;
    height: 58px;
    margin-right: 70px;
  }
}

.text {
  h1 {
    font-size: 36px;
  }
}

.tagsContainer {
  font-size: 200%;
  div {
    padding: 16px 32px;
    margin: var(--size-2);
  }
}

.title {
  @include limitTextLines(2);
}

.right {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 10;
  box-sizing: border-box;
}

.rating {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 32px 0 16px 0;
}

.cover {
  width: min-content;
  position: relative;
  flex: 0 0 240px;
  width: 240px;
  img {
    width: 240px;
  }
}

.avatar {
  position: absolute;
  top: -37px;
  right: -37px;
  z-index: 3;
}

.square {
  width: 840px;
  height: 840px;
  .left {
    width: 100%;
    flex: 1 1 700px;
    align-items: flex-start;
    justify-content: flex-start;
    .logo {
      width: 255px;
      flex: 0 0 255px;
      align-self: flex-start;
    }
    .cover {
      margin-top: 20px;
      width: 190px;
      max-width: 190px;
      flex: 1 1 190px;

      img {
        width: 190px;
      }
    }
  }
}

.portrait {
  padding-top: 270px;
  width: 1080px;
  height: 1920px;
  .left {
    flex-direction: column;
    .logo {
      flex: auto;
      width: 100%;
      margin: 0;
      justify-content: center;
      svg {
        margin: 0;
      }
    }
    .cover {
      margin-top: 144px;
      flex: auto;
      width: 400px;
      justify-content: center;
      img {
        width: 400px;
      }
    }
  }
  .right {
    margin-top: 80px;
    justify-content: flex-start;
  }
}

.square,
.portrait {
  flex-direction: column;
  .right {
    width: 100%;
  }
}
