@import "ui/styles/mixins";
.container {
  background-color: var(--uiBoxBackground);
  border-radius: 4px;
  padding: var(--size-5);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--size-4);

  align-items: center;
  justify-items: center;

  transition: background-color 100ms linear;
  @media (hover: hover) {
    &:hover {
      --isHover: 1;
      background-color: var(--uiHover);
    }
  }

  @media (min-width: 600px) {
    padding: var(--size-8);
    grid-gap: var(--size-7);
    grid-template-columns: 145px 1fr;
    justify-items: left;
  }
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media (min-width: 600px) {
    align-items: flex-start;
    text-align: left;
  }
}
