@import "ui/styles/mixins";
.container {
  width: 1200px;
  height: 630px;
  overflow: hidden;
  display: flex;
  padding: 70px;
  background-color: var(--uiWhite);
  // todo maybe use later
  // &:before {
  //   content: '';
  //   width: 50%;
  //   height: 80%;
  //   position: absolute;
  //   top: -10%;
  //   right: -15%;
  //   background: var(--gradient);
  //   border-radius: 40%;
  //   opacity: 0.95;
  //   filter: blur(100px);
  //   -webkit-backface-visibility: hidden;
  //   -webkit-perspective: 1000;
  //   -webkit-transform: translate3d(0, 0, 0);
  //   -webkit-transform: translateZ(0);
  //   backface-visibility: hidden;
  //   perspective: 1000;
  //   transform: translate3d(0, 0, 0);
  //   transform: translateZ(0);

  //   @media (min-width: 900px) {
  //     filter: blur(200px);
  //   }
  // }

  // &:after {
  //   content: '';
  //   width: 60%;
  //   height: 70%;
  //   position: absolute;
  //   left: -20%;
  //   bottom: -20%;
  //   background: var(--gradient);
  //   border-radius: 40%;
  //   opacity: 0.8;
  //   filter: blur(120px);
  //   -webkit-backface-visibility: hidden;
  //   -webkit-perspective: 1000;
  //   -webkit-transform: translate3d(0, 0, 0);
  //   -webkit-transform: translateZ(0);
  //   backface-visibility: hidden;
  //   perspective: 1000;
  //   transform: translate3d(0, 0, 0);
  //   transform: translateZ(0);

  //   @media (min-width: 900px) {
  //     filter: blur(200px);
  //   }
  // }
}

.logo {
  flex: 0 0 42px;
  svg {
    width: 42px;
    height: 58px;
  }
}

.text {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.title {
  color: var(--textPrimary);
  font-size: 54px;
  line-height: 81px;
  @include limitTextLines(3);
}

.authors {
  margin-top: 15px;
  font-size: 38px;
  line-height: 1.5;
  text-align: center;
  color: var(--textDiscreet);
  @include limitTextLines(3);
  a {
    color: var(--textDiscreet);
  }
}

.book {
  display: flex;
  align-items: center;
  margin: 0 70px;
  flex: 0 0 240px;
}

.square {
  width: 840px;
  height: 840px;
  flex-direction: column;
  .book {
    justify-content: center;
    padding-bottom: 20px;
  }
}

.portrait {
  width: 1080px;
  height: 1920px;
  flex-direction: column;
  justify-content: center;
  .book {
    justify-content: center;
  }
  .logo {
    text-align: center;
    margin-bottom: 144px;
  }
  .text {
    height: max-content;
    margin-top: 120px;
  }
  .title {
    font-size: 62px;
    line-height: 81px;
    @include limitTextLines(3);
  }

  .subtitle {
    font-family: Libre Baskerville;
    font-size: 62px;
    font-style: italic;
    @include limitTextLines(3);
    color: var(--textPrimary);
  }
}
