@import "ui/styles/mixins";
.container {
  width: 100%;
  max-width: 560px;
  border-bottom: var(--outlineWidth) solid var(--uiOutline);
  margin-bottom: 40px;
  padding-bottom: 40px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.content {
  margin-top: 20px;
  @media (min-width: 600px) {
    padding-left: 54px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatar {
  display: flex;
  align-items: center;
  align-self: flex-start;
  position: relative;
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.text {
  margin-left: 14px;
  display: inline;
  * {
    display: inline;
  }
}

.date {
  display: block;
  flex: 0 0 auto;
  margin-left: 14px;
  @media (min-width: 600px) {
    margin-top: 0;
    margin-left: 20px;
  }
}

.name {
  font-weight: bold;
  color: var(--textPrimary);
  transition: color 200ms ease-in-out;
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimary);
    }
  }
  &:active {
    color: var(--accentPrimaryActive);
  }
}
