@import "ui/styles/mixins";
.quote,
.note {
  font-size: 16px;
  line-height: 1.5;
  max-width: 100%;
  word-wrap: break-word;
  &:last-child {
    margin-bottom: 0;
  }
}

.quote {
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
  margin-top: 20px;
  white-space: pre-wrap;
  @media (min-width: 600px) {
    padding-left: 25px;
  }

  &::before {
    content: '';
    width: 3px;
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 0;
    border-radius: 3px;
    background-color: var(--uiOutline);
    background: var(--gradient);
  }
}

.page {
  color: var(--textDiscreet);
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.4;
}

.note {
  margin-top: 20px;
  color: var(--textPrimary);
}
