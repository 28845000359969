@import "ui/styles/mixins";
.group {
  display: flex;

  > button:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
  }
}
