@import "ui/styles/mixins";
.container {
  display: inline-flex;
  vertical-align: bottom;
}

.gradient {
  :global(.part1) {
    transition: all 150ms ease;
    stop-color: var(--textBlack);
  }

  :global(.part2) {
    stop-color: var(--uiWhite);
  }
}

.starIcon {
  stroke: var(--textBlack);
  box-sizing: content-box;
  position: relative;
  display: flex;
  justify-content: center;

  svg {
    transition: all 150ms ease;
    position: absolute;
  }

  .starIconHalf {
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    z-index: 2;
    cursor: pointer;

    &.starIconHalf1 {
      left: 0;
    }
    &.starIconHalf2 {
      right: 0;
    }
  }
}

.ratingInputContainer {
  border: 1px solid var(--uiInput);
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;

  .starIcon {
    cursor: pointer;
  }
}

.textInputContainer {
  color: var(--textBlack);

  > div {
    // safari glitch
    padding: 12px 0px 12px 0;
  }

  .textInput {
    color: var(--textBlack);
    background-color: transparent;
    width: 50px;
    border: none;
    outline: none;
    text-align: right;
    padding: 10px 4px 10px 0;
  }
}

.noValue {
  svg {
    stroke: var(--textDiscreet);
  }

  .textInputContainer,
  .textInputContainer .textInput,
  .textInputContainer span {
    color: var(--textDiscreet);
  }
}

.hovering {
  .starIcon,
  svg {
    stroke: var(--textPrimary);
  }

  :global(.part1) {
    stop-color: var(--textPrimary);
  }

  .textInputContainer,
  .textInput {
    color: var(--textPrimary);
  }
}
