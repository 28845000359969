@import "ui/styles/mixins";
.radio {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  border: var(--outlineWidth) solid var(--uiInput);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  transition: border ease-in-out 200ms;
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: var(--textPrimary);
    transform: scale(0);
    transition: transform 200ms ease-in-out;
  }
  @media (hover: hover) {
    &:hover {
      border-color: var(--uiBlack);
    }
  }
}
.radio.checked {
  background-color: var(--uiHover);
  position: relative;
  &::after {
    transform: scale(1);
  }

  &::before {
    content: 'Clear';
    position: absolute;
    left: -44px;
  }
}
