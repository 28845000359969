@import "ui/styles/mixins";
.container {
  width: 1200px;
  height: 630px;
  overflow: hidden;
  display: flex;
  padding: 70px 70px 0 70px;
  background-color: var(--uiWhite);
  position: relative;
}

.image {
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 70px;
  padding-right: 40px;
  .cover {
    width: 240px;
    img {
      width: 240px;
    }
  }
}

.bookInfo {
  display: none;
}

.logo {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-right: 50px;
  svg {
    width: 42px;
    height: 58px;
  }
}

.info {
  display: inline-block;
  white-space: nowrap;
  margin: auto 0;
  min-width: 0;
}

.inner {
  white-space: normal;
  display: inline-block;
  vertical-align: middle;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 400px;
    height: 154px;
    background: linear-gradient(360deg, rgba(255, 255, 255, 1) 20.93%, rgba(255, 255, 255, 0) 100%);
    @media (prefers-color-scheme: dark) {
      background: linear-gradient(360deg, rgba(0, 0, 0, 1) 20.93%, rgba(0, 0, 0, 0) 100%);
    }
  }
}

.quote,
.note {
  flex: 0 0 732px;
  margin-left: 14px;
  font-size: 38px;
  line-height: 53.2px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 900px) {
    margin-left: 54px;
    font-size: 40px;
  }
}

.quote {
  padding-left: 35px;
  position: relative;

  &::before {
    content: '';
    width: 5px;
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 0;
    border-radius: 3px;
    background-color: var(--uiOutline);
    background: var(--gradient);

    @media (min-width: 900px) {
      width: 7px;
    }
  }

  @media (min-width: 900px) {
    padding-left: 45px;
  }
}

.note {
  margin-top: 20px;
  color: var(--textPrimary);
}

.bookBar {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    height: 150px;
    width: 100%;
    position: absolute;
    top: -150px;
    background: linear-gradient(360deg, rgba(255, 255, 255, 1) 20.93%, rgba(255, 255, 255, 0) 100%);
    @media (prefers-color-scheme: dark) {
      background: linear-gradient(360deg, rgba(0, 0, 0, 1) 20.93%, rgba(0, 0, 0, 0) 100%);
    }
  }
}

.square {
  width: 840px;
  height: 840px;
  padding: 64px 64px 0 64px;

  .inner {
    max-height: 600px;
    overflow: hidden;
  }
  .logoSquare {
    svg {
      width: 42px;
      height: 58px;
    }
  }
  .bookBar {
    position: absolute;
    bottom: 0;
    z-index: 3;
    background-color: var(--textWhite);
    padding-bottom: 40px;
    .bookInfo {
      max-width: 500px;
    }
  }
  .cover {
    flex: 0 0 63px;
    width: 63px;
  }
}

.square,
.portrait {
  flex-direction: column;
  justify-content: center;
  .bookBar {
    justify-self: flex-end;
  }
  .bookInfo {
    padding-left: 58px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 38px;
    line-height: 150%;
    height: 100%;
    margin-right: 42px;

    .title,
    .authors {
      @include limitTextLines(1);
    }
    .authors {
      color: var(--textSecondary);
    }
  }
  .quote,
  .note {
    margin-left: 0;
  }

  .inner {
    margin-bottom: 150px;
    &::after {
      display: none;
    }
  }
}

.portrait {
  width: 1080px;
  height: 1920px;
  flex-direction: column;
  padding: 62px 130px 228px 80px;
  .logo {
    width: 100%;
    justify-content: center;
    align-self: center;
    margin-bottom: 69px;
    margin-top: auto;
  }
  .info {
    margin: 200px 0 0 0;
    height: auto;
    overflow: hidden;
  }
  .inner {
    max-height: 1313px;
    overflow: hidden;
  }
  .image {
    left: 150px;
    img {
      width: 110px;
    }
  }
  .quote,
  .bookInfo {
    font-size: 56px;
    line-height: 78.4px;
    overflow: hidden;
  }
  .logoSquare {
    display: none;
  }
}
