@import "ui/styles/mixins";
.tag {
  display: inline-block;
  background: var(--uiWhite);
  color: var(--textDiscreet);
  border: var(--outlineWidth) solid var(--uiInput);
  padding: 8px 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 100px;
  user-select: none;
  white-space: nowrap;

  &.isEditable {
    cursor: pointer;
    @media (hover: hover) {
      &:hover {
        border-color: var(--primary);
      }
    }
  }

  &.tagActive {
    color: var(--textPrimary);
    background-color: var(--uiBoxBackground);
    border-color: var(--uiBoxBackground);
  }
}
