@import "ui/styles/mixins";
.dropdownButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 48px;
  background: var(--uiBlack);
  color: var(--textWhite);
  border-radius: 2px;
  border: 0 none;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.15s linear;

  border-left: var(--outlineWidth) solid var(--uiWhite);
  padding: 0 var(--size-3) 0;
  margin: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @media (hover: hover) {
    &:hover {
      background: var(--textPrimary);
      border-color: var(--textPrimary);
      border-left: var(--outlineWidth) solid var(--uiWhite);
    }

    &:active {
      background: rgba(196, 194, 186, 0.3);
      border-color: rgba(196, 194, 186, 0.3);
      border-left: var(--outlineWidth) solid var(--uiWhite);
    }
  }

  &.mini {
    padding: var(--size-1);
    height: 34px;
  }
}

:global(.bodyUiBoxBackground) .dropdownButtonInverse:not(:hover) {
  background-color: var(--uiBoxBackground);
}

.dropdownButtonInverse {
  display: inline-flex;
  background-color: var(--uiWhite);
  color: var(--textBlack);
  border-radius: 2px !important;
  border: var(--outlineWidth) solid var(--uiBlack);

  svg {
    flex: 0 0 15px;
    stroke: var(--textBlack);
    transition: all 0.15s linear;
    margin-left: var(--size-3);
  }
  @media (hover: hover) {
    &:hover {
      border-left: var(--outlineWidth) solid var(--uiSecondary);
      color: var(--textWhite);
      svg {
        stroke: var(--textWhite);
      }
    }
  }

  &.mini {
    padding: 5px 10px;

    svg {
      margin-left: var(--size-2);
    }
  }
}

.inverseInner {
  min-width: 105px;
  text-align: left;
}
