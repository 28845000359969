@import "ui/styles/mixins";
.links {
  font-weight: bold;

  font-weight: 600;
  color: var(--textPrimary);
  &:active {
    color: var(--accentPrimaryActive);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--accentPrimaryHover);
    }
  }
}