@import "ui/styles/mixins";
.text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  > * {
    display: inline;
  }
}
