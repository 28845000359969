@import "ui/styles/mixins";
.container {
  display: inline-flex;
  width: 100%;
  position: relative;

  .right {
    flex: 1 0 auto;
    margin-left: var(--size-4);
    max-width: calc(100% - (40px + var(--size-4)));
  }
}
.sendButton {
  margin-top: var(--size-2);
  text-align: right;
}
