@import "ui/styles/mixins";
.controls {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textarea {
  min-height: 100px;
}
