@import "ui/styles/mixins";
.container {
  width: 1200px;
  height: 630px;
  overflow: hidden;
  flex-direction: column;
  display: flex;
  padding: 70px;
  background-color: var(--uiWhite);
  &.completed {
    background-image: url('/img/confetti.svg');
  }
}

.top {
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  svg {
    width: 42px;
    height: 58px;
  }
}

.avatar {
  display: flex;
  justify-content: center;
}

.bottom {
  padding: var(--size-6) 0 0 var(--size-6);
  display: flex;
  flex-direction: row;
}

.goalInfo {
  font-size: 54px;
  line-height: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 64px;
  font-family: 'Libre Baskerville', serif;
}

.goalHeader {
  font-size: 36px;
  line-height: 150%;
  color: var(--textSecondary);
  font-family: 'Inter', sans-serif;
}

.large {
  display: flex;
  align-items: center;
  font-size: 98px;
  height: 118px;
}

.booksText {
  font-size: 32px;
  color: var(--textDiscreet);
}

.square {
  width: 840px;
  height: 840px;
  .goalInfo {
    margin-top: 30px;
  }
  .progress {
    width: 367px;
    height: 367px;
  }
}

.portrait {
  width: 1080px;
  height: 1920px;
  padding-top: 260px;
  .top {
    width: 217px;
    align-self: center;
    margin-bottom: 133px;
  }
  .goalInfo {
    margin-top: 120px;
    width: 100%;
    @include limitTextLines(3);
  }
  .progress {
    width: 673px;
    height: 673px;
  }
}

.square,
.portrait {
  .bottom {
    flex-direction: column;
    align-content: center;
    .progress {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
    }
  }
  .name {
    text-align: center;
  }
  .goalInfo {
    padding-left: 0;
  }
}
