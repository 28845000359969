@import "ui/styles/mixins";
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  :global(.sectionHeader) {
    margin-bottom: 10px;

    &:first-child {
      margin-top: 0 !important;
    }

    &:global(.italic) {
      margin-top: 20px;
    }
  }

  .tagsContainer {
    margin-top: 16px;
  }

  .reviewBody {
    text-align: left;
    white-space: pre-line;
    word-wrap: break-word;
    p:last-child {
      padding-bottom: 0;
    }
  }

  .reviewBodyContainer {
    position: relative;
    width: 100%;
  }
}

.coverInner {
  position: relative;
  --isHover: 0;
  transition: all 300ms ease;
  margin-top: var(--size-8);

  @media (hover: hover) {
    transform: translateY(calc(var(--isHover) * -4px));
    &:hover {
      --isHover: 1;
    }
  }
}

.coverHeight {
  display: block;
  height: 196px;
}
